// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-boutiques-js": () => import("./../../../src/pages/boutiques.js" /* webpackChunkName: "component---src-pages-boutiques-js" */),
  "component---src-pages-brochures-js": () => import("./../../../src/pages/brochures.js" /* webpackChunkName: "component---src-pages-brochures-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-enquiries-js": () => import("./../../../src/pages/press-enquiries.js" /* webpackChunkName: "component---src-pages-press-enquiries-js" */),
  "component---src-pages-shows-js": () => import("./../../../src/pages/shows.js" /* webpackChunkName: "component---src-pages-shows-js" */),
  "component---src-pages-stockists-index-js": () => import("./../../../src/pages/stockists/index.js" /* webpackChunkName: "component---src-pages-stockists-index-js" */),
  "component---src-pages-stockists-locator-js": () => import("./../../../src/pages/stockists/locator.js" /* webpackChunkName: "component---src-pages-stockists-locator-js" */),
  "component---src-pages-wholesale-application-complete-js": () => import("./../../../src/pages/wholesale/application/complete.js" /* webpackChunkName: "component---src-pages-wholesale-application-complete-js" */),
  "component---src-pages-wholesale-application-index-js": () => import("./../../../src/pages/wholesale/application/index.js" /* webpackChunkName: "component---src-pages-wholesale-application-index-js" */),
  "component---src-pages-wholesale-application-start-js": () => import("./../../../src/pages/wholesale/application/start.js" /* webpackChunkName: "component---src-pages-wholesale-application-start-js" */),
  "component---src-pages-wholesale-index-js": () => import("./../../../src/pages/wholesale/index.js" /* webpackChunkName: "component---src-pages-wholesale-index-js" */),
  "component---src-templates-collection-index-js": () => import("./../../../src/templates/collection/index.js" /* webpackChunkName: "component---src-templates-collection-index-js" */),
  "component---src-templates-legal-index-js": () => import("./../../../src/templates/legal/index.js" /* webpackChunkName: "component---src-templates-legal-index-js" */),
  "component---src-templates-page-category-summary-js": () => import("./../../../src/templates/page/category-summary.js" /* webpackChunkName: "component---src-templates-page-category-summary-js" */),
  "component---src-templates-page-production-partner-js": () => import("./../../../src/templates/page/production-partner.js" /* webpackChunkName: "component---src-templates-page-production-partner-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-production-partners-index-js": () => import("./../../../src/templates/production-partners/index.js" /* webpackChunkName: "component---src-templates-production-partners-index-js" */),
  "component---src-templates-stockist-index-js": () => import("./../../../src/templates/stockist/index.js" /* webpackChunkName: "component---src-templates-stockist-index-js" */)
}

